import React, { useEffect, useState } from "react";
import Modal from "./Modal"; // Assume you have a Modal component
import { fetchMessageReferences } from "../services/ApiService"; // Import the method from ApiService

interface Reference {
  id: string;
  text: string; // Description of the reference
  document_type: string;
  document_name: string; // Name of the document
}

interface ReferenceModalProps {
  chatId: number;
  messageId: number;
  isVisible: boolean;
  onClose: () => void;
}

const ReferenceModal: React.FC<ReferenceModalProps> = ({
  chatId,
  messageId,
  isVisible,
  onClose,
}) => {
  const [references, setReferences] = useState<Reference[]>([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Fetch references dynamically when the modal is opened
  useEffect(() => {
    const loadReferences = async () => {
      setLoading(true);
      try {
        const referencesData = await fetchMessageReferences(chatId, messageId);
        if (referencesData) {
          setReferences(referencesData);
          setErrorMessage("");
        } else setErrorMessage("No references found");
      } catch (error) {
        console.error("Error fetching references:", error);
        setErrorMessage("Error fetching references");
      } finally {
        setLoading(false);
      }
    };

    if (isVisible) {
      loadReferences();
    }
  }, [chatId, messageId, isVisible]);

  const handleOpenPdf = (fileName: string) => {
    const url = `${process.env.PUBLIC_URL}/${fileName}`;
    window.open(url, "_blank");
  };

  if (!isVisible) return null;

  return (
    <Modal isVisible={isVisible} onClose={onClose} size="large">
      <h2 className="text-xl font-semibold mb-4">References</h2>
      {errorMessage ? (
        <p>{errorMessage}</p>
      ) : loading ? (
        <p>Loading references...</p>
      ) : (
        <div className="max-h-96 overflow-y-auto">
          {references.map((reference) => (
            <div
              key={reference.id}
              className="mb-4 p-2 border-b last:border-none"
            >
              <h4 className="font-semibold">
                {reference.document_name.replace(/\.[^/.]+$/, "")}
              </h4>
              <p className="text-gray-600">{reference.text}</p>{" "}
              {/* Description */}
              <button
                className="mt-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
                onClick={() => handleOpenPdf(reference.document_name)} // Open the PDF
              >
                Open PDF
              </button>
            </div>
          ))}
        </div>
      )}
    </Modal>
  );
};

export default ReferenceModal;
