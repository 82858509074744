import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../context/AuthContext";
import LoadingScreen from "../components/LoadingScreen";
import Sidebar from "../components/Sidebar";
import RightSidebar from "../components/RightSidebar";
import ChatSection from "../components/ChatSection";
import {
  fetchConversations,
  fetchMessages,
  sendMessage,
  addConversation,
  deleteConversation,
  updateConversationName,
  getUserInfo,
} from "services/ApiService";
import { FiSettings } from "react-icons/fi";
import Spinner from "components/Spinner";
import SettingsModal from "components/SettingsModal";
import Conversation from "models/Conversation";
import Message from "models/Message";
import EightDSource from "components/EightDSource";
import { useNavigate } from "react-router-dom";

const Index: React.FC = () => {
  const navigate = useNavigate(); // For redirection
  const sections: Array<"D3" | "D4" | "D5" | "D7"> = ["D3", "D4", "D5", "D7"];
  const { logout, user } = useAuth();

  // State management
  const [refreshTrigger, setRefreshTrigger] = useState(0); // Add refreshTrigger state

  const [loading, setLoading] = useState<boolean>(true);
  const [selectingConversation, setSelectingConversation] =
    useState<boolean>(false);
  const [conversations, setConversations] = useState<Conversation[] | null>(
    null
  );
  const [currentConversationId, setCurrentConversationId] = useState<
    number | null
  >(null);
  const [currentConversationDate, setCurrentConversationDate] = useState<
    string | null
  >(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userInfo, setUserInfo] = useState({
    username: "",
    email: "",
    name: "",
    password: "",
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [saveSuccess, setSaveSuccess] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [D3Content, setD3Content] = useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [D4Content, setD4Content] = useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [D5Content, setD5Content] = useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [D7Content, setD7Content] = useState<string>("");
  const [documentTypes, setDocumentTypes] = useState<string[]>([]); // Store selected document types

  // Notify when content changes for the sidebar
  const [sidebarKey, setSidebarKey] = useState<number>(0);
  const handleComponentUpdate = () => {
    setRefreshTrigger((prev) => prev + 1);
  };
  // Load section data from localStorage for a specific conversation
  const loadSectionDataFromStorage = (chatId: number | null) => {
    if (!chatId) return;

    const storedData = JSON.parse(localStorage.getItem("chatData") || "{}");

    setD3Content(storedData[chatId]?.D3 || "");
    setD4Content(storedData[chatId]?.D4 || "");
    setD5Content(storedData[chatId]?.D5 || "");
    setD7Content(storedData[chatId]?.D7 || "");
  };

  // Handle adding a new conversation
  const handleAddConversation = useCallback(async () => {
    try {
      setSelectingConversation(true);
      const newConversation = await addConversation();
      setConversations((prev) =>
        prev ? [...prev, newConversation] : [newConversation]
      );
      setCurrentConversationId(newConversation.ID);
      setCurrentConversationDate(newConversation.CreatedAt);
      setMessages([
        {
          ID: 0,
          ChatID: newConversation.ID,
          Question: "",
          Prompt: "",
          Answer: "Welcome to your new conversation!",
          CreatedAt: new Date().toISOString(),
        },
      ]);
      loadSectionDataFromStorage(newConversation.ID); // Load section data for new conversation
    } catch (error) {
      console.error("Error adding conversation:", error);
    } finally {
      setSelectingConversation(false);
    }
  }, []);
  // Check if the user session is valid
  useEffect(() => {
    const checkSession = async () => {
      try {
        await getUserInfo(); // Try fetching user info
      } catch (error) {
        console.error("Session invalid, redirecting to login.");
        navigate("/login"); // Redirect to login on error
      }
    };

    checkSession();
  }, [navigate]);
  // Initial load of conversations
  useEffect(() => {
    const loadConversations = async () => {
      try {
        const data = await fetchConversations();
        if (data.length > 0) {
          setConversations(data);
          const firstConversation = data[0];
          setCurrentConversationId(firstConversation.ID);
          setCurrentConversationDate(firstConversation.CreatedAt);
          const messagesData = await fetchMessages(firstConversation.ID);
          setMessages(messagesData);
          loadSectionDataFromStorage(firstConversation.ID); // Load section data for the first conversation
        } else {
          handleAddConversation();
        }
      } catch (error) {
        console.error("Error loading conversations:", error);
      } finally {
        setLoading(false);
      }
    };

    loadConversations();
  }, [handleAddConversation]);

  // Handle message sending and response fetching
  const handleSendMessage = useCallback(
    async (userInput: string) => {
      if (userInput.trim() === "") return;

      const userMessage: Message = {
        ID: 0,
        ChatID: currentConversationId!,
        Question: userInput,
        Prompt: "",
        Answer: "",
        CreatedAt: new Date().toISOString(),
      };

      setMessages((prevMessages) => [...prevMessages, userMessage]);

      try {
        if (currentConversationId) {
          const systemResponse = await sendMessage(
            currentConversationId,
            userInput,
            documentTypes
          );

          const systemMessage: Message = {
            ID: systemResponse.ID,
            ChatID: systemResponse.ChatID,
            Question: "",
            Prompt: "",
            Answer: systemResponse.Answer,
            CreatedAt: systemResponse.CreatedAt,
          };

          setMessages((prevMessages) => [...prevMessages, systemMessage]);
          const updatedConversations = await fetchConversations();
          const updatedConversation = updatedConversations.find(
            (conv) => conv.ID === currentConversationId
          );

          if (updatedConversation) {
            setConversations(
              (prev) =>
                prev?.map((conv) =>
                  conv.ID === updatedConversation.ID
                    ? { ...conv, ChatName: updatedConversation.ChatName }
                    : conv
                ) || []
            );
          }
        }
      } catch (error) {
        console.error("Failed to fetch system response:", error);
      }
    },
    [currentConversationId, documentTypes]
  );

  // Handle selecting a conversation
  const handleSelectConversation = useCallback(
    async (id: number) => {
      if (currentConversationId === id) return; // No need to reselect the current conversation

      setSelectingConversation(true);
      try {
        const messagesData = await fetchMessages(id);
        setMessages(messagesData);
        const selectedConversation = conversations?.find(
          (conv) => conv.ID === id
        );
        if (selectedConversation) {
          setCurrentConversationId(selectedConversation.ID);
          setCurrentConversationDate(selectedConversation.CreatedAt);
          loadSectionDataFromStorage(selectedConversation.ID); // Load section data for selected conversation
        }
      } catch (error) {
        console.error("Error loading messages:", error);
      } finally {
        setSelectingConversation(false);
      }
    },
    [currentConversationId, conversations]
  );

  // Handle renaming a conversation
  const handleEditConversation = useCallback(
    async (id: number, newTitle: string) => {
      try {
        // Call the API to update the conversation name
        await updateConversationName(id, newTitle);

        // Update the conversation name in the UI
        setConversations(
          (prevConversations) =>
            prevConversations?.map((conv) =>
              conv.ID === id ? { ...conv, ChatName: newTitle } : conv
            ) || []
        );
      } catch (error) {
        console.error("Error updating conversation name:", error);
      }
    },
    []
  );

  // Handle deleting a conversation
  const handleDeleteConversation = useCallback(
    async (id: number) => {
      try {
        setSelectingConversation(true);

        // Await the deletion of the conversation
        await deleteConversation(id);

        // Fetch updated list of conversations after deletion
        const updatedConversations = await fetchConversations();

        setConversations(updatedConversations); // Update state with new list

        // Check if the deleted conversation is the currently active one
        if (currentConversationId === id) {
          if (updatedConversations.length > 0) {
            const firstConversation = updatedConversations[0];
            setCurrentConversationId(firstConversation.ID);
            setCurrentConversationDate(firstConversation.CreatedAt);

            // Fetch the messages for the newly selected conversation
            const messagesData = await fetchMessages(firstConversation.ID);
            setMessages(messagesData);
            loadSectionDataFromStorage(firstConversation.ID); // Load section data for new conversation
          } else {
            // No conversations left, create a new one automatically
            await handleAddConversation();
          }
        }
      } catch (error) {
        console.error("Error deleting conversation:", error);
      } finally {
        setSelectingConversation(false);
      }
    },
    [currentConversationId, handleAddConversation]
  );

  // Handle copying to sections
  const handleCopyToSection = (
    messageText: string,
    section: "D3" | "D4" | "D5" | "D7"
  ) => {
    const chatId = currentConversationId;
    if (!chatId) return;

    if (section === "D3") setD3Content(messageText);
    if (section === "D4") setD4Content(messageText);
    if (section === "D5") setD5Content(messageText);
    if (section === "D7") setD7Content(messageText);

    // Store in localStorage
    const storedData = JSON.parse(localStorage.getItem("chatData") || "{}");

    const updatedData = {
      ...storedData,
      [chatId]: {
        ...(storedData[chatId] || {}),
        [section]: messageText,
      },
    };

    localStorage.setItem("chatData", JSON.stringify(updatedData));

    setSidebarKey((prevKey) => prevKey + 1); // Trigger sidebar re-render
  };

  if (loading) {
    return <LoadingScreen duration={1000} />;
  }

  return (
    <div className="h-screen flex flex-col">
      <div className="flex justify-between items-center p-4 bg-white text-blue-700 border-b border-gray-100">
        <h1 className="text-3xl font-semibold">
          Deep<span className="text-violet-700 font-bold">Q</span>
        </h1>
        <div className="flex space-x-4">
          <button
            onClick={() => setIsModalVisible(true)}
            className="flex items-center bg-gray-200 text-black px-4 py-2 rounded-lg hover:bg-violet-700 hover:text-white transition"
            title="Settings"
          >
            <span className="mr-2">Settings</span>
            <FiSettings />
          </button>
        </div>
      </div>

      <div className="flex-1 flex h-full overflow-hidden">
        {/* Sidebar and 8D Source in one column */}
        <div className="w-1/6 flex flex-col">
          {/* Sidebar occupies the majority of the column */}
          <Sidebar
            conversations={conversations || []}
            onAdd={handleAddConversation}
            onEdit={handleEditConversation}
            onDelete={handleDeleteConversation}
            onSelect={handleSelectConversation}
            currentConversationId={currentConversationId}
          />

          {/* 8D Source component at the bottom */}
          <div className="mt-auto">
            <EightDSource
              selectedDocumentTypes={documentTypes}
              onSelectionChange={setDocumentTypes}
              refreshTrigger={refreshTrigger} // Pass refreshTrigger
            />
          </div>
        </div>

        {/* Chat section to fill the remaining space */}
        <div className="flex-1 h-full flex flex-col">
          <ChatSection
            messages={messages}
            onSendMessage={handleSendMessage}
            sections={sections}
            handleCopyToSection={handleCopyToSection}
            creationDate={currentConversationDate}
          />
        </div>

        {/* Right sidebar taking up 1/3 width and full height */}
        <div className="w-1/3 h-full flex flex-col">
          <RightSidebar
            key={sidebarKey}
            currentConversationId={currentConversationId}
          />
        </div>
      </div>

      <SettingsModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        userInfo={userInfo}
        onInputChange={() => {}}
        onSave={() => {}}
        saveSuccess={saveSuccess}
        user={user}
        logout={logout}
        onComponentUpdate={handleComponentUpdate}
      />
      {selectingConversation && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default Index;
